<template>
  <main>
    <div class="contenair">
      <div class="popup_wrapper">
        <div class="popup popup-status">
          <div id="banner" class="banner banner-status">
            <div :style="`background-image: url(${datas.banner_link})`">
              <img alt="empty" class="vide" src="/vide.png" />
              <div class="status-content">
                <Form
                  v-if="datas.statusID"
                  id="form"
                  :form_id="datas.statusID"
                  :uid="uid"
                  :submission_id="submission_id"
                  :server="datas.serverUrl"
                  v-on:results="getResult"
                  v-on:form_submit_loading="loading"
                  @on_error="viewError"
                  :hideError="hiderror"
                />
              </div>
            </div>
          </div>

          <div id="home" class="home" v-if="participation === 'open'">
            <img alt="logo" class="logo" :src="`${datas.logo}`" />
            <h2 v-html="datas.title" />
            <h3 class="periode">
              <span v-if="datas.lang != 'uk'">{{ datas.langText.dateFrom }}</span>
              <span v-else>From</span>
              {{ date_debut }}
              <span v-if="datas.lang != 'uk'">au</span>
              <span v-else>To</span>
              {{ date_fin_achat }}
            </h3>

            <div
              v-if="
                datas.counter.limit &&
                  datas.counter.type &&
                  datas.counter.display != 'hidden' &&
                  datas.counter.type != 'INFORMATIVE'
              "
              class="counter"
            >
              <div class="counter-infos">
                <section v-if="datas.counter.increment">{{ datas.langText.counterTextStartIncrement }}</section>
                <section v-else>{{ datas.langText.counterTextStart }}</section>
                <span id="counter-rest">{{ datas.counter.restant }}</span>
                {{ datas.compteur_text_after }}
              </div>
              <div v-if="datas.counter.display == 'progressbar'" class="barras">
                <div class="barra">
                  <div class="barra-nivel" id="cpt" data-nivel="90%"></div>
                </div>
              </div>
            </div>

            <div
              v-if="
                datas.counter.type &&
                  datas.counter.display != 'hidden' &&
                  datas.counter.type != 'LIMITATIVE'
              "
              class="counter"
            >
              <p>{{ datas.counter.total }} {{ datas.compteur_text_after }}</p>
            </div>

            <p
              v-if="datas.text_home && participation === 'open'"
              v-html="datas.text_home"
            />
            <div class="button_wrapper"></div>

            <a
              v-if="datas.modalites_uri"
              :href="`${datas.modalites_uri}`"
              target="_blank"
              rel="noopener"
              class="moda_link"
            >
              <span v-if="datas.lang != 'uk'">{{ datas.langText.modalitesText }}</span>
              <span v-else>View rules</span>
            </a>

            <a
              v-if="datas.reglement_uri"
              :href="`${datas.reglement_uri}`"
              target="_blank"
              rel="noopener"
              class="moda_link"
            >
              <span v-if="datas.lang != 'uk'">{{ datas.langText.reglementText }}</span>
              <span v-else>View rules</span>
            </a>
          </div>

          <div id="home" class="home" v-if="participation != 'open'">
            <img alt="logo" class="logo" :src="`${datas.logo}`" />
            <h2 v-html="datas.title" />
            <h3 class="periode">
              <span v-if="datas.lang != 'uk'">{{ datas.langText.dateFrom}}</span>
              <span v-else>From</span>
              {{ date_debut }}
              <span v-if="datas.lang != 'uk'">{{ datas.langText.dateTo}}</span>
              <span v-else>To</span>
              {{ date_fin_achat }}
            </h3>

            <p
              v-if="participation === 'wait'"
              class="homeclose_txt"
              v-html="datas.text_wait"
            />
            <p
              v-if="participation === 'finish'"
              class="homeclose_txt"
              v-html="datas.text_finish"
            />
            <p
              v-if="participation === 'overquota'"
              class="homeclose_txt"
              v-html="datas.text_quotafull"
            />

            <a
              v-if="datas.modalites_uri"
              :href="`${datas.modalites_uri}`"
              target="_blank"
              rel="noopener"
              class="moda_link"
            >
              <span v-if="datas.lang != 'uk'">{{ datas.langText.modalitesText }}</span>
              <span v-else>View rules</span>
            </a>

            <a
              v-if="datas.reglement_uri"
              :href="`${datas.reglement_uri}`"
              target="_blank"
              rel="noopener"
              class="moda_link"
            >
              <span v-if="datas.lang != 'uk'">{{ datas.langText.reglementText }}</span>
              <span v-else>View rules</span>
            </a>
          </div>

          <div class="tablette-content">
            <div id="mentions" class="mentions">
              <img alt="logo" class="mobile logo" :src="`${datas.logo}`" />
              <div v-html="datas.mentions" />
              <button
                v-if="!datas.statuspage"
                class="middle-off retour"
                @click="home"
              >
                <span>{{ datas.langText.retourButton}}</span>
              </button>
              <button
                v-if="!datas.statuspage"
                class="tablette_only retour"
                @click="openTablettePage('form')"
              >
                <span>{{ datas.langText.retourButton}}</span>
              </button>
              <button
                v-if="!datas.statuspage"
                class="mobile retour"
                @click="openMobilePage('home')"
              >
                <span>{{ datas.langText.retourButton}}</span>
              </button>
            </div>

            <div id="contact" class="contact">
              <img alt="logo" class="mobile logo" :src="`${datas.logo}`" />
              <div v-html="datas.contact" />
              <button
                v-if="!datas.statuspage"
                class="middle-off retour"
                @click="home"
              >
                <span>{{ datas.langText.retourButton}}</span>
              </button>
              <button
                v-if="!datas.statuspage"
                class="tablette_only retour"
                @click="openTablettePage('form')"
              >
                <span>{{ datas.langText.retourButton}}</span>
              </button>
              <button
                v-if="!datas.statuspage"
                class="mobile retour"
                @click="openMobilePage('home')"
              >
                <span>{{ datas.langText.retourButton}}</span>
              </button>
            </div>

            <div id="done" class="done">
              <img alt="logo" class="lg" :src="`${datas.logo}`" />

              <div v-if="endstart" class="success-animation">
                <svg
                  class="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    class="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    class="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
              <div>
                <p v-html="datas.text_submited" />
              </div>
              <button class="middle-off retour" @click="home">
                <span>{{ datas.langText.retourButton}}</span>
              </button>
              <button class="middle retour" @click="openMobilePage('home')">
                <span>{{ datas.langText.retourButton}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
var dayjs = require("dayjs");
import mongr from '@/libs/dayjsGr.js'

const locales = {
  de: () => import("dayjs/locale/de"),
  ar: () => import("dayjs/locale/ar"),  
  fr: () => import("dayjs/locale/fr"),
  befr: () => import("dayjs/locale/fr"),
  benl : () => import("dayjs/locale/nl-be"),
  nl: () => import("dayjs/locale/nl"),
  pl: () => import("dayjs/locale/pl"),
  es: () => import("dayjs/locale/es"),
  "es-cat": () => import("dayjs/locale/en"),
  it: () => import("dayjs/locale/it"),
  en: () => import("dayjs/locale/en"),
  ro: () => import("dayjs/locale/ro"),
  sl: () => import("dayjs/locale/sl"),
  bg: () => import("dayjs/locale/bg")
};

export default {
  name: "status",
  data: function() {
    return {
      $swalInstance: null,
      datas: this.$root.configOp.datas,
      date_debut: undefined,
      date_fin: undefined,
      date_fin_achat: undefined,
      endstart: false,
      participation: this.$root.configOp.datas.participation,
      banner_link: this.$root.configOp.datas.banner_link,
      step: 1,
      total_step: 0,
      submited: false,
      nb_step: 0,
      hiderror: true,
      uid: this.$route.params._key,
      submission_id: this.$route.params._sub_id,
      server: undefined,
      date_debut: undefined,
      date_fin: undefined,
      state: "left",
      content: "home",
      contact_state: 0,
    };
  },
  mounted() {
    if (this.datas.statuspage) document.getElementById("participer").click();
    this.loadLocale(this.datas.lang)
  },
  methods: {
    loadLocale() {
      var language = this.datas.lang
console.log('---------------',language)
      if(language==='es-es') language='es'
      if(language==='be-nl' || language==='lu-nl') language='nl'
      if(language==='be-fr' || language==='lu-fr') language='fr' 
      if(language==='si') language='sl'       
      if(language==='uk') language='en' 
      if(language==='gr') { 
        dayjs.locale('gr', mongr);
      } else {
        locales[language]().then(() => dayjs.locale(language));
        dayjs.locale(language) 
      }     

        setTimeout(() => {
        if(this.datas.lang != "uk") {
          this.date_debut = dayjs(this.$root.configOp.datas.date_debut).format("DD MMMM YYYY");
          this.date_fin = dayjs(this.$root.configOp.datas.date_fin);
          this.date_fin_achat = dayjs(this.$root.configOp.datas.date_fin_achat).format("DD MMMM YYYY");   
        } else {
          this.date_debut = dayjs(this.$root.configOp.datas.date_debut).format("MMMM DD[th]");
          this.date_fin = dayjs(this.$root.configOp.datas.date_fin);
          this.date_fin_achat = dayjs(this.$root.configOp.datas.date_fin_achat).format("MMMM DD[th] YYYY");
        }}, 200);
    },
    async $swal(args) {
      if (!this.$swalInstance) {
        await import(/* webpackChunkName: "swal" */ "sweetalert2").then(
          (sweetAlert) => {
            this.$swalInstance = sweetAlert.default;
          }
        );
      }

      this.$swalInstance(args);
    },
    endForm() {
      this.submited = true;
      this.content = "done";
      var element1 = document.getElementById("done");

      if (document.body.offsetWidth > 1000) {
        setTimeout(() => {
          this.endstart = true;
        }, 500);

        var el = document.getElementById("banner");
        el.classList.remove("bannerBack");

        setTimeout(function() {
          element1.style.left = "0px";
          element1.classList.add("moveDone");
        }, 500);

        var element = document.getElementById("home");
        element.style.display = "none";

        var element = document.getElementById("banner");
        element.classList.add("moveEnd");

        var element = document.getElementById("form");
        element.classList.add("divtoend");
      } else {
        setTimeout(() => {
          this.submited = false;
        }, 1500);

        this.endstart = true;
        element1.style.left = "0px";
        var element = document.getElementById("form");
        element.style.display = "none";
        element1.classList.add("moveDone");
        element1.style.display = "flex";
      }
    },
    openParticiperMobile() {
      document.getElementById("home").style.display = "none";
      this.openMiddlePage("form");
    },
    openTablettePage: function(name) {
      this.openMiddlePage(name);
    },
    openMobilePage: function(name) {
      document.getElementById("home").style.display = "none";

      this.openMiddlePage(name);

      if (name == "home") {
        document.getElementById("banner").style.display = "block";
      } else {
        document.getElementById("banner").style.display = "none";
      }
    },
    openMiddlePage: function(name) {
      document.getElementById("done").style.display = "none";
      document.getElementById("form").style.display = "none";
      document.getElementById("mentions").style.display = "none";
      document.getElementById("contact").style.display = "none";
      document.getElementById(name).style.opacity = 1;

      if (name === "form") {
        document.getElementById(name).style.display = "block";
      } else {
        document.getElementById(name).style.display = "flex";
      }
    },
    closeSubmited: function() {
      var el = document.getElementById("done");
      var el1 = document.getElementById("banner");

      el.classList.remove("moveDone");
      el.classList.add("closeDone");

      setTimeout(function() {
        el1.classList.add("banner-left");
        el.classList.remove("closeDone");
        el.style.left = "99999px";
      }, 1000);

      el1.classList.remove("moveEnd");
      el1.classList.remove("banner-left");
      el1.classList.add("bannerBack");

      var el2 = document.getElementById("form");
      el2.classList.remove("left");
      el2.classList.remove("divtoend");

      this.submited = false;
    },
    openUp(name) {
      if (this.submited == true) this.closeSubmited();

      if (this.content == name) return false;
      if (this.state == "right") {
        var el0 = document.getElementById(this.content); // ancien contenu
        el0.classList.remove("banner-left");
        el0.classList.add("hidetoleft");

        var el = document.getElementById(name); // nouveau contenu
        el.classList.remove("close");
        el.classList.remove("left");
        el.classList.add("banner-left");

        setTimeout(function() {
          el.classList.remove("banner-left");
          el.classList.add("left");
          el0.classList.remove("left");
          el0.classList.remove("hidetoleft");
        }, 500);
      } else {
        var element = document.getElementById("banner");
        element.classList.remove("banner-right");
        element.classList.add("banner-left");

        const cbox = document.querySelectorAll("#mentions, #contact");
        for (let i = 0; i < cbox.length; i++) {
          cbox[i].classList.remove("left");
          cbox[i].classList.add("close");
        }
        var el = document.getElementById(name);
        el.classList.remove("close");
        el.classList.add("left");
      }
      this.state = "right";
      this.content = name;
    },
    home: function() {
      var element = document.getElementById("banner");

      if (this.submited == true) {
        this.closeSubmited();
      }

      if (this.state != "left") {
        var el = document.getElementById("home");
        el.style.display = "flex";
        element.classList.remove("bannerBack");
        element.classList.remove("banner-left");
        element.classList.add("banner-right");

        setTimeout(function() {
          const cbox = document.querySelectorAll("#mentions, #contact");
          for (let i = 0; i < cbox.length; i++) {
            cbox[i].classList.remove("left");
          }
        }, 500);
        this.state = "left";
      }
      this.content = "home";
    },
    animateValue: function(obj, start, end, duration) {
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        obj.innerHTML = Math.floor(progress * (end - start) + start);
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
    loading(value) {
      if (value === true) {
        this.$swal({
          title: this.datas.langText.sendText,
          html: this.datas.langText.pleaseWaitText,
          timerProgressBar: true,
          onBeforeOpen: () => {
            this.$swalInstance.showLoading();
          },
        });
      }
    },
    addStep() {
      this.step = this.step + 1;
    },
    supStep() {
      this.step = this.step - 1;
    },
    getResult(result) {
      
        if (result._id) {

           this.$swalInstance.close();
            this.endForm();
          
      }
    },
    viewError(err) {
      this.$swal({
        confirmButtonText: "OK",
        confirmButtonColor: "#C94330",
        title: this.datas.langText.errorText,
        text: "",
      }).then(function() {
        window.location.href = "/";
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/templates/gabarit1.scss";

.menu-mobile {
  @media screen and (max-width: 540px) {
    display: block !important;
  }
}
</style>
